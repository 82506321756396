.tableHead {
  padding: 0 !important;
  font-size: .82rem !important;
  font-weight: bold !important;
}

.dateRange {
  border: 1px solid #1976d2;
  border-radius: 5px;
  outline: none;
  height: 1rem;
  margin-top: 2px;
  padding: 10px;
}

.css-bkzwk2-MuiStack-root {
  margin-top: -10px;
  overflow: hidden !important;
}

.css-1nf681w-MuiTableCell-root {
  padding: 0 !important;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 1.65em !important;
}

.tableRow {
  font-size: .8rem !important;
}
/*# sourceMappingURL=index.86f69f6a.css.map */
